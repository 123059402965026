export default {
    isAuth(state){
        return state.user.token ? true : false;
    },
    getUserToken(state){
        return state.user.token;
    },
    getUserName(state){
        return state.user.name;
    },
    getCategories(state){
        return state.categories;
    },
    getCartItems(state){
        return state.cart;
    },
    countItemsInCart(state){
        let count = 0;
        state.cart.forEach(elm => {
            count += elm.amount;
        });
        return count; 
    },
    getLayoutControllers(state){
        return state.layoutControllers;
    }
}