<template>
  <AppHeader v-if="$store.getters.isAuth"></AppHeader>
  <router-view></router-view>
  <AppFooter v-if="$store.getters.isAuth"></AppFooter>
</template>

<script>
import $ from "jquery";

import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  watch:{
    $route (to, from){
      this.$store.commit("hideAllSidebars");
    }
},
  mounted(){
    if(localStorage.getItem("user")){
      this.$store.commit("setUserData", JSON.parse(localStorage.getItem("user")));  
    }

    if(localStorage.getItem("cart")){
      this.$store.commit("setCartData", JSON.parse(localStorage.getItem("cart")));  
    }

    $(window).on("scroll", function () {
    var scrolltop = $(this).scrollTop();

    if (scrolltop >= 1) {
      $("#header").addClass("scroll");
    } else if (scrolltop <= 1) {
      $("#header").removeClass("scroll");
    }
  });
  }
}
</script>

<style>
  @import '@/assets/styles/main.css';
</style>
