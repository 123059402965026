<template>
    <div class="breadcrumbs">
        <ul>
            <li><router-link :to="'/'">Home</router-link></li>
            <li v-for="(item, key) in data" :key="key">
                <template v-if="key == data.length-1">{{ item.name }}</template>
                <router-link v-else :to="'/'+item.slug">{{ item.name }}</router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'BreadcrumbsNav',
    props: ['data'],
}
</script>
<style scoped></style>