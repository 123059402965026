<template>
<section id="category-page" class="page-container">
    <section id="category-page-header">
        <div class="wrapper">
            <div class="header favourites">
             <h1 class="categoryName">Ulubione <span class="amount">2</span></h1>
            </div>
        </div>
    </section>
    <section id="products-list">
        <div class="wrapper">
        </div>
    </section>
</section>
</template>
<script>
export default{
name: 'FavouritesPage'
}
</script>
<style scoped>
</style>