<template>
    <div>
        <footer :class="{'marginBottom': !ifCheckout}">
            <div class="wrapper">
            <div class="logo">
                    <a href="#">
                    <img src="@/assets/images/logo-white.svg" alt="Fluidra Polska" />
                    </a>
                </div>

                <div class="contact-cta">
                    <div class="left-side">
                        <p><b>Masz pytania? Potrzebujesz pomocy?</b></p>
                        <p>Skontaktuj się z naszym działem handlowym</p>
                    </div>
                    <div class="right-side">
                        <ul>
                            <li class="tel"><a href="tel:(+48) 000 000 000">(+48) 000 000 000</a></li>
                            <li class="email"><a href="mailto:biuro@fludra.com">biuro@fludra.com</a></li>
                    
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <transition name="slide-left" mode="out-in">
        <NavigationSidebar v-if="getLayoutControllers.navigationSidebar"></NavigationSidebar>
        </transition>
        <transition name="slide-right" mode="out-in">
            <CartSidebar v-if="getLayoutControllers.cartSidebar"></CartSidebar>
        </transition>
        <section @click.prevent="hideAllSidebars()" id="sidebar-mask" :class="{'visible': getLayoutControllers.mask }"></section>
    </div>
</template>
<script>
import CartSidebar from './sidebars/CartSidebar.vue';
import NavigationSidebar from './sidebars/NavigationSidebar.vue';

export default{
name: 'AppFooter',
components: {
    NavigationSidebar, 
    CartSidebar
},
computed: {
    getLayoutControllers(){
        return this.$store.getters.getLayoutControllers;
    },
    ifCheckout(){
        return this.$route.name === 'checkout' ? true : false;
        }
},
methods:{
    hideAllSidebars(){
        this.$store.commit("hideAllSidebars");
    }
}
}
</script>
<style>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  
  -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);

}

.slide-right-enter-from,
.slide-right-leave-to {
    -webkit-transform: translate(100%, 0px);
    -ms-transform: translate(100%, 0px);
    transform: translate(100%, 0px);
}
.slide-left-enter-from,
.slide-left-leave-to {
    -webkit-transform: translate(-100%, 0px);
    -ms-transform: translate(-100%, 0px);
    transform: translate(-100%, 0px);
}
</style>