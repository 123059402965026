<template>
   <section id="category-page" class="page-container">
    <section id="category-page-header" class="search-page">
        <div class="wrapper">
            <div class="header">
             <h1 class="categoryName" v-if="resultsQuery && isDataFetched">Wyniki wyszukiwania dla: {{ resultsQuery }}</h1>
             <h1 v-else>Szukaj produktów</h1>
            </div>
            <form action="#" id="search" @submit.prevent="submitSearchForm">
              <input type="text" name="s" minlength="3" placeholder="Szukaj po nazwie lub kodzie produktu" v-model="searchQuery">
              <button type="submit"><img src="@/assets/images/search.svg" alt=""></button>
            </form>
        </div>
    </section>
    <section id="no-results" v-if="total == 0 && isDataFetched">
        <div class="wrapper">
        <div class="icon">
            <img src="@/assets/images/no-results.svg" class="" loading="lazy" />
        </div>
        <div class="desc">
            <p>Brak wyników dla Twojego zapytania.</p>
            <ul>
                <li><router-link :to="'/'"><button>Strona główna</button></router-link></li>
                <li><router-link :to="'/favourites'"><button>Ulubione</button></router-link></li>
            </ul>
        </div>
        </div>
    </section>
    <section id="products-list" v-else-if="isDataFetched">
        <div class="wrapper">
            <div class="top-label">
                <p>Ilość wyników: {{ total }}</p>
            </div>
            <div class="items">
                <ProductTile v-for="(item, key) in results" :key="key" :product="item"></ProductTile>
            </div>
        </div>
    </section>
</section>
</template>
<script>
import axios from 'axios';
import ProductTile from './parts/ProductTile';

export default{
name: 'SearchPage',

data(){
    return {
        searchQuery: '',
        resultsQuery: '',
        results: [],
        total: 0,
        isDataFetched: false
    }
},
components: {
    ProductTile
},
methods: {
    getSearchResult(query){
        if(query !== ""){
            const apiURL = "wp/v2/products/?per_page=100&search="+query;
            axios.get(apiURL).then( (response) => {
                this.results = response.data;
                this.total = response.headers['x-wp-total'];
    
                this.resultsQuery = query;
                this.isDataFetched = true;
    
            } );
        }
    },
    submitSearchForm(){
        this.getSearchResult(this.searchQuery);
        this.$router.push({path: '/search', query: { s: this.searchQuery }}) 
    }
},
watch:{
    $route (to, from){
        this.getSearchResult(this.$route.query.s);
        this.searchQuery = this.$route.query.s;
    }
},
mounted(){
    if(this.$route.query.s){
        this.searchQuery = this.$route.query.s;
        this.getSearchResult(this.$route.query.s);
    }
}
}
</script>
<style scoped>
</style>