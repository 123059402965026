<template>
  <div>

    <section id="header-checkout" v-if="ifCheckout">
        <div class="main-bar">
        <div class="wrapper">
            <div class="goBack">
            <button class="back" @click.prevent="$router.go(-1)"><span>Powrót</span></button></div>
            <div class="logo">
              <router-link :to="'/home'">
                <img src="@/assets/images/logo.svg" alt="Fluidra Polska" />
              </router-link>
            </div>
        </div>
        </div>
    </section>

    <section v-else id="header">
      <div class="main-bar">
        <div class="wrapper">
          <div class="logo">
            <router-link :to="'/home'">
              <img src="@/assets/images/logo.svg" alt="Fluidra Polska" />
            </router-link>
          </div>
          <div class="btn-menu" @click.prevent="toggleSidebar('navigationSidebar', true)">
            <div class="mobile-menu-icon">
              <button class="menu-toggle">Menu</button>
            </div>
            <span class="label">Kategorie produktów</span>
          </div>
          <div class="right-side">
            <SearchForm></SearchForm>

            <ul>
              <li class="search">
                <router-link :to="'/search'">
                  <button>
                    <img class="on" src="@/assets/images/search.svg" alt="">
                    <img class="off" src="@/assets/images/search.svg" alt="">
                  </button>
                </router-link>
              </li>
              <li class="favourites">
                <router-link :to="'/favourites'">
                  <button>
                    <img class="on" src="@/assets/images/favourites-on.svg" alt="">
                    <img class="off" src="@/assets/images/favourites-off.svg" alt="">
                  </button>
                </router-link>
              </li>
              <li class="cart">
                <button class="openCartSidebar" @click.prevent="toggleSidebar('cartSidebar', true)">
                  <span class="amount" v-if="countItemsInCart > 0">{{ countItemsInCart }}</span>
                  <img class="on" src="@/assets/images/cart-on.svg" alt="">
                  <img class="off" src="@/assets/images/cart-off.svg" alt="">
                </button>
              </li>
              <li>
                <button @click.prevent="toggleSidebar('profileWindow', !profileWindowState)">
                  <img class="on" src="@/assets/images/profile-on.svg" alt="">
                  <img class="off" src="@/assets/images/profile-off.svg" alt="">
                </button>
                <transition name="fade" mode="out-in" appear>
                  <ProfileWindow v-if="profileWindowState"></ProfileWindow>
                </transition>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <MobileNavBar v-if="!ifCheckout"></MobileNavBar>
  </div>
</template>
<script>
import MobileNavBar from './parts/MobileNavBar.vue';
import SearchForm from './parts/SearchForm.vue';
import ProfileWindow from './sidebars/ProfileWindow.vue';

export default {
  name: 'AppHeader',
  data() {
    return {
      layoutControllers: {
        profileWindow: false
      }
    }
  },
  components: {
    ProfileWindow,
    SearchForm,
    MobileNavBar
  },
  computed: {
    profileWindowState() {
      return this.$store.state.layoutControllers.profileWindow;
    },
    countItemsInCart() {
      return this.$store.getters.countItemsInCart;
    },
    ifCheckout(){
      return this.$route.name === 'checkout' ? true : false;
    }
  },
  methods: {
    toggleSidebar(window, changeTo) {
      this.$store.commit("changeLayout",
        {
          key: window,
          value: changeTo
        })
    }
  }
}
</script>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 1;
  -webkit-transform: translate(0%, 100%);
  -ms-transform: translate(0%, 100%);
  transform: translate(0%, 100%);

  @media only screen and (min-width: 992px) {
    -webkit-transform: translate(0%, 15px);
    -ms-transform: translate(0%, 15px);
    transform: translate(0%, 15px);
    opacity: 0;

  }
}
</style>