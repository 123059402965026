<template>
    <div class="subCategories">
        <ul>
            <li v-for="(item, key) in data" :key="key">
                <router-link :to="'/'+item.slug">
                    <button>{{ item.name }}</button>
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'SubCategoriesNav',
    props: ['data']
}
</script>
<style scoped></style>