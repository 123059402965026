<template>
    <section id="single-product" class="page-container">
        <BreadcrumbsNav v-if="isDataFetched" :data="getPathToBreadcrumbs"></BreadcrumbsNav>
    <div class="wrapper"  v-if="isDataFetched">
        <div class="product">
            <div class="oneoftwo">
                <div class="product-img">
                    <img :src="product.featured_image_src" :alt="product.title.rendered">
                </div>
            </div>
            <div class="oneoftwo">
                <h1 class="productName" v-html="product.title.rendered"></h1>
                <span class="code">{{ product.acf.code }}</span>
                <div class="global-content-config" v-if="product.content.rendered" v-html="product.content.rendered"></div>
                <ul class="price">
                    <!-- <li class="discount">-22%</li> -->
                    <li class="currentPrice">{{ product.acf.regular_price.toFixed(2) }} €</li>
                    <!-- <li class="regularPrice"><span>250,00</span> €</li> -->
                </ul>
                <p class="price-info">Podane ceny są kwotami netto, do których zostanie doliczone 23% podatku VAT.</p>
                <div class="cart-favourites">
                    <form action="#">
                        <div class="amount">
                            <button class="less" @click.prevent="amount > 1 ? amount-- : amount;">-</button>
                            <input type="number" value="1" min="1" v-model="amount">
                            <button class="more" @click.prevent="amount++;">+</button>
                        </div>
                        <button class="submit addToCart" @click.prevent="addToCart(product.id, amount)"><span>Dodaj do koszyka</span></button>
                    </form>
                    <button class="addToFavourites"><span>Dodaj do ulubionych</span></button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; 

import BreadcrumbsNav from './parts/BreadcrumbsNav';

export default{
name: 'SingleProduct',
data(){
    return {
        product: null,
        amount: 1,
        isDataFetched: false
    }
},
components: {
    BreadcrumbsNav
},
computed: {
    getProductlug(){
        return this.$route.params.productSlug;
    },
    getPathToBreadcrumbs(){
        let path = [];
        if(this.$store.getters.getCategories){
            this.product['products-categories'].forEach(elm => {
                let category = _.filter(this.$store.getters.getCategories, { 'id': elm });
                    path.push({
                        slug: category[0].slug,
                        name: category[0].name,
                        parent: category[0].parent
                    })
            });
            path = _.sortBy(path, [['parent']]);
        }
        path.push({
            slug: this.product.slug,
            name: this.product.title.rendered
        })
        return path;
    }
},
methods: {
    getSingleProduct(){
        const apiURL = "wp/v2/products?slug="+this.getProductlug;
        axios.get(apiURL).then( ({ data }) => {
            this.product = data[0];
            this.isDataFetched = true;
        } );
    },
    addToCart(id, amount){
        this.$store.commit("addItemToCart", {
            id,
            amount
        });

        const notyf = new Notyf({
            dismissible: true,
        });

        const notification = notyf.success('Produkt został dodany do koszyka');
        notification.on('click', ({target, event}) => {
            this.$store.commit("changeLayout", 
                { 
                    key: 'cartSidebar', 
                    value: true
                })
        });
    }
},
watch:{
    $route (to, from){
        this.getSingleProduct()
    }
},
mounted(){
    this.getSingleProduct()
}
}
</script>
<style scoped>
</style>