<template>
    <section id="home-page" class="page-container">
    </section>
</template>
<script>
import Navigation from './sidebars/NavigationSidebar.vue';
export default{
name: 'Home',
components: {
    //Navigation
},
methods:{

},
mounted(){
    if(!this.$store.getters.getCategories){
        this.$store.commit("setCategories");
    }
}
}
</script>
<style scoped>
</style>