<template>
    <div class="profileWindow">
        <div class="in">
            <div class="header">
                <span class="windowHeader">Moje konto</span>
                <p>Użytkownik: {{ userName }}</p>
                <img @click.prevent="closeProfileWindow()" src="@/assets/images/close.svg" class="close" alt="">
            </div>
            <ul>
                <li class="profile"><router-link :to="'/account'">Moje zapytania</router-link></li>
                <li class="logOut"><a href="#" @click.prevent="logOut()">Wyloguj się</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProfileWindow',
    computed: {
        userName() {
            return this.$store.getters.getUserName;
        }
    },
    methods: {
        logOut() {
            this.$store.commit("logOutUser");
            this.$router.push('/login');
        },
        closeProfileWindow() {
            this.$store.commit("changeLayout",
                {
                    key: 'profileWindow',
                    value: false
                })
        }
    }
}
</script>
<style scoped></style>