<template>
<section id="menuSidebar" :class="{'active': sidebarState}">
<div class="header">
    <h3><span>Kategorie produktów</span></h3>
    <img @click.prevent="closeSidebar()" src="@/assets/images/close.svg" class="close" loading="lazy"/>
</div> 
<SearchForm></SearchForm>

<div class="menu-items">
    <ul id="categoriesNav">
    <li v-for="(item, key) in getMainCategories" :class="{'hasChild': itemHasChild(item.id)}">
        <router-link :to="'/' + item.slug">
            {{ item.name }}
        </router-link>
        <ul v-if="itemHasChild(item.id)">
            <li v-for="(subItem, key) in getSubCategories(item.id)">
                <router-link :to="'/' + subItem.slug">
                    {{ subItem.name }}
                </router-link>
            </li>
        </ul>
    </li>
</ul>
</div>
</section>
</template>
<script>
import $ from "jquery";
import axios from 'axios';
import _ from 'lodash';

import SearchForm from '../parts/SearchForm.vue';


export default{
name: 'Navigation',
data(){
    return {

    }
},
components: {
    SearchForm
  },
methods: {
    getSubCategories(parentId){
        return _.filter(this.getCategories, { 'parent': parentId });
    },
    itemHasChild(parentId){
        return _.find(this.getCategories, { 'parent': parentId }) ? true : false;

    },
    closeSidebar(){
            this.$store.commit("changeLayout", 
                    { 
                        key: 'navigationSidebar', 
                        value: false
                    })
        }
},
computed: {
    getCategories(){
        return this.$store.getters.getCategories;
    },
    getMainCategories(){
        return _.filter(this.getCategories, { 'parent': 0 });
    },
    sidebarState(){
        return this.$store.state.layoutControllers.navigationSidebar;
    }
},
mounted(){

    if(!this.$store.getters.getCategories){
        this.$store.commit("setCategories");
    }

    $('ul#categoriesNav li').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            if(!$(e.currentTarget).hasClass('menu-visible')){
            $('ul#categoriesNav li.hasChild').removeClass('menu-visible');      
            $('ul#categoriesNav li.hasChild ul').slideUp(300);
            }
            $(e.currentTarget).toggleClass('menu-visible');
            $(e.currentTarget).find('ul').slideToggle(300);
        });
}
};
</script>
<style scoped>
ul {
    text-align: left;
}
</style>