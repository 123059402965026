<template>
    <div>
        <div class="header">
            <h2>Dane kontaktowe</h2>
        </div>
        <form action="#">
            <label for="name">Imię i nazwisko*</label>
            <input type="text" name="name" placeholder="Imię i nazwisko" 
            v-model="form.name" 
            @blur="v$.form.name.$touch"
            :class="{'invalid': v$.form.name.$dirty && !v$.form.name.required.$response}"
            >
            <p class="invalid" v-if="v$.form.name.$dirty && !v$.form.name.required.$response">To pole jest wymagane.</p>

            <label for="email">Twój adres e-mail*</label>
            <input type="email" name="email" placeholder="Twój adres e-mail" 
            v-model="form.email"
            @blur="v$.form.email.$touch"
            :class="{'invalid': v$.form.email.$dirty && (!v$.form.email.required.$response || !v$.form.email.email.$response )}"
            >
            <p class="invalid" v-if="v$.form.email.$dirty && !v$.form.email.required.$response">To pole jest wymagane.</p>
            <p class="invalid" v-if="v$.form.email.$dirty && !v$.form.email.email.$response">Adres email jest nieprawidłowy.</p>

            <label for="message">Miejsce na Twoje uwagi</label>
            <textarea name="message" id="message" v-model="form.message"></textarea>
            <fieldset>
                <label>
                    <input type="checkbox" name="checkbox" value="value" 
                    v-model="form.allowance"
                    @blur="v$.form.allowance.$touch"
                    :class="{'invalid': v$.form.allowance.$dirty && !v$.form.allowance.$model}"

                    >
                    <span>Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiscing elit, sed do eiusmod tempor incusmod tempor incididunt ut labore
                        et dolore magna aliqua. </span></label>
                <p class="invalid" v-if="v$.form.allowance.$dirty && !v$.form.allowance.$model" >Musisz wyrazić zgodę przed przesłaniem zapytania.</p>

            </fieldset>
            <button type="submit" @click.prevent="submitForm()">Prześlij zapytanie</button>
        </form>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'

export default {
    name: 'CheckoutForm',
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
            form: {
                name: "",
                email: "",
                message: "",
                allowance: false
            }
        }
    },
    validations () {
        return {
            form: {
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                allowance: {
                    required,
                    checked: sameAs(true)
                }
            }
         }
        },
        methods: {
            submitForm(){
                if(!this.v$.$invalid){
                    console.log('submit form');
                } else {
                    this.v$.form.name.$dirty = true;
                    this.v$.form.email.$dirty = true;
                    this.v$.form.allowance.$dirty = true;
                }
            }
        }
}
</script>
<style scoped></style>