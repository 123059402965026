<template>
<section id="checkout" class="page-container">
<div class="wrapper">
    <div class="left-side">
    <CheckoutForm></CheckoutForm>
    </div>
    <div class="right-sidebar">
    <div class="header">
        <h2>Podsumowanie</h2>
    </div>
    <button class="showCart">Podejrzyj koszyk</button>
    <CartProductsList></CartProductsList>
    </div>
</div>
</section>
</template>
<script>
import $ from "jquery";

import CheckoutForm from './parts/CheckoutForm';
import CartProductsList from './parts/CartProductsList';

export default{
name: 'CheckoutPage',
    components: {
        CartProductsList,
        CheckoutForm
    },
    mounted(){
    $('button.showCart').on('click', function(e){
        $('.products-list .items').slideToggle(300);
    });
}
}
</script>
<style scoped>
</style>