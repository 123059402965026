<template>
    <div class="item product-tile">
        <router-link :to="'product/'+product.slug">
        <div class="inner">
            <div class="thumb">
                <img :src="product.featured_image_src" alt="">
            </div>
            <span class="name" v-html="product.title.rendered"></span>
            <span class="code">{{ product.acf.code }}</span>
        </div>
    </router-link>
    </div>
</template>
<script>
export default {
    name: 'ProductTile',
    props: ['product']
}
</script>
<style scoped></style>