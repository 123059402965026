<template>
    <div class="item">
        <div class="thumb">
            <button class="remove" @click.prevent="removeFromCart(data.id)">
                <img src="@/assets/images/close.svg" class="" loading="lazy" />
            </button>
            <img :src="data.featured_image_src" class="" loading="lazy" />
        </div>
        <div class="desc">
            <router-link :to="'/product/'+data.slug"><span class="productName" v-html="data.title.rendered"></span></router-link>
            <p class="atributes">{{data.acf.code}}</p>
            <p class="atributes">Ilość: {{ amount }}</p>
            <div class="price">
                <span class="current">{{ data.acf.regular_price.toFixed(2) }} €</span>
                <!-- <span class="del">79,00 zł</span> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CartItem',
    props: ['data', 'amount'],
    methods: {
        removeFromCart(id){
            this.$store.commit("removeFromCart", id);
        }
    }
}
</script>
<style scoped></style>