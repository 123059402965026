<template>
    <div>
        <table>
            <tbody>
                <tr>
                    <td>Suma (cena katalogowa):</td>
                    <td><del>1200,00</del> €</td>
                </tr>
                <tr>
                    <td>Suma (po rabacie):</td>
                    <td>900,00 €</td>
                </tr>
                <tr>
                    <td><b>Razem w PLN:</b>Kurs EUR/PLN: 4,30 zł</td>
                    <td>4050,00 zł</td>
                </tr>
            </tbody>
        </table>
        <p class="price-info">Podane ceny są kwotami netto, do których zostanie doliczone 23% podatku VAT.</p>
    </div>
</template>
<script>
export default {
    name: 'CartSummaryTable'
}
</script>
<style scoped></style>