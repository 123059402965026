import { createStore } from 'vuex'
import mutations from './mutations';
import getters from './getters';
import actions from './actions';



export default createStore({
    state () {
      return {
            user: {
                token: '',
                name: ''
            },
            layoutControllers: {
                profileWindow: false,
                navigationSidebar: false,
                cartSidebar: false,
                mask: false
            },
            categories: null,
            cart: []
      }
    },
    mutations,
    getters,
    actions
  })