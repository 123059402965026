<template>

    <section id="bottom-navigationBar">
      <ul>
        <li>
            <router-link :to="'/home'">
            <button>
              <div class="icon">
                <img class="on" src="@/assets/images/home-on.svg" alt="">
                <img class="off" src="@/assets/images/home.svg" alt="">
              </div>
              <span>Start</span>
            </button>
          </router-link>
        </li>
        <li>
          <button data-event="mobile-panel-menu" @click.prevent="toggleSidebar('navigationSidebar', !sidebarsState.navigationSidebar)">
            <div class="icon">
              <img class="on" src="@/assets/images/categories-on.svg" alt="">
              <img class="off" src="@/assets/images/categories.svg" alt="">
            </div>
            <span>Kategorie</span>
          </button>
        </li>
        <li>
            <router-link :to="'/favourites'">
            <button class="indicator">
              <div class="icon">
                <!-- <span class="amount">0</span> -->
                <img class="on" src="@/assets/images/bottom-favourite-on.svg" alt="">
                <img class="off" src="@/assets/images/bottom-favourite.svg" alt="">
              </div>
              <span>Ulubione</span>
            </button>
            </router-link>
          </li>
        <li>
            <button data-event="cartSidebar" class="indicator" @click.prevent="toggleSidebar('cartSidebar', !sidebarsState.cartSidebar)">
              <div class="icon">
                <span class="amount">{{countItemsInCart}}</span>
                <img class="on" src="@/assets/images/bottom-cart-on.svg" alt="">
                <img class="off" src="@/assets/images/bottom-cart.svg" alt="">
              </div>
              <span>Koszyk</span>
            </button>
          </li>
      </ul>
    </section>

</template>
<script>
export default{
name: 'MobileNavBar',
computed: {
    sidebarsState() {
      return this.$store.state.layoutControllers;
    },
    countItemsInCart() {
      return this.$store.getters.countItemsInCart;
    }
  },
methods: {
    toggleSidebar(window, changeTo) {
        this.$store.commit('hideAllSidebars');
      this.$store.commit("changeLayout",
        {
          key: window,
          value: changeTo
        })
    }
  }
}
</script>
<style scoped>
</style>