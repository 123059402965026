<template>
<section id="my-orders" class="page-container">
   <div class="wrapper">
   <div class="header">
        <h1>Moje zapytania</h1>
    </div>
    <table>
        <thead>
            <tr>
                <th>Zapytanie</th>
                <th>Data</th>
                <th>Osoba kontaktowa</th>
                <th>Suma</th>
                <th>Akcja</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>#114</td>
                <td>03 Grudnia 2024</td>
                <td>Jan Kowalski</td>
                <td>149,99 zł za 3 pozycje</td>
                <td><button>Zobacz</button></td>
            </tr>
            <tr>
                <td>#114</td>
                <td>03 Grudnia 2024</td>
                <td>Jan Kowalski</td>
                <td>149,99 zł za 3 pozycje</td>
                <td><button>Zobacz</button></td>
            </tr> <tr>
                <td>#114</td>
                <td>03 Grudnia 2024</td>
                <td>Jan Kowalski</td>
                <td>149,99 zł za 3 pozycje</td>
                <td><button>Zobacz</button></td>
            </tr>
        </tbody>
    </table>
   </div>
</section>
</template>
<script>
export default{
name: 'AccountPage'
}
</script>
<style scoped>
</style>