import axios from 'axios';
import _, { each } from 'lodash';
export default {
    setUserData(state, payload){
        state.user = payload;
    },
    setCartData(state, payload){
        return state.cart = payload;
    },
    logOutUser(state){
        state.user.token = '';
        state.user.name = '';
        localStorage.removeItem("user");
    },
    setCategories(state){
        axios.get("wp/v2/products-categories")
        .then( ({ data }) => state.categories = data );
    },
    addItemToCart(state, payload){
       let isInCart = _.findIndex(state.cart, function(o) { return o.id == payload.id; });
        if(isInCart >= 0){
           state.cart.splice(isInCart, 1);
        }
        state.cart.push(payload);
        localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    changeLayout(state, payload){
        state.layoutControllers[payload.key] = payload.value;
        if(payload.key === "navigationSidebar" || payload.key === "cartSidebar"){
            state.layoutControllers.mask = payload.value;
        }
    },
    hideAllSidebars(state){
        for (const property in state.layoutControllers) {
            state.layoutControllers[property] = false;
        }
    },
    removeFromCart(state, payload){
        let index = _.findIndex(state.cart, { 'id': payload });
        state.cart.splice(index, 1);
        localStorage.setItem("cart", JSON.stringify(state.cart));
    }
}