<template>
    <form action="#" id="search" @submit.prevent="submitSearchForm">
        <input type="text" name="s" value="" minlength="3" placeholder="Szukaj po nazwie lub kodzie produktu" v-model="searchQuery">
        <button type="submit"><img src="@/assets/images/search.svg" alt=""></button>
    </form>
</template>
<script>
export default {
    name: 'SearchForm',
    data(){
        return {
            searchQuery: ''
        }
    },

methods: {
    submitSearchForm(){
        if(this.searchQuery !== ""){
            this.$router.push({path: '/search', query: { s: this.searchQuery }}) 
        }
    }
},
}
</script>
<style scoped></style>