import Login from '../components/Login'; 
import Home from '../components/Home'; 
import ProductsCategory from '../components/ProductsCategory'; 
import SingleProduct from '../components/SingleProduct';
import SearchPage from '../components/SearchPage';
import CheckoutPage from '../components/CheckoutPage';
import FavouritesPage from '../components/FavouritesPage';
import AccountPage from '../components/AccountPage';


import store from '../store/store';

const authGuard = (to, from, next) => {
    if(store.getters.isAuth){
        next();
    } else{
        next({name: 'login'})
    }
}

const notAuthGuard = (to, from, next) => {
    if(!store.getters.isAuth){
        next();
    } else{
        next({name: 'home'})
    }
}

export default [
    { path: "/", redirect: '/login' }, 
    { 
        name: 'login',
        path: "/login", 
        component: Login, 
        beforeEnter: notAuthGuard
    },
    { 
        name: 'home',
        path: "/home", 
        component: Home,
        beforeEnter: authGuard
    },
    { 
        name: 'category',
        path: "/:categorySlug", 
        component: ProductsCategory,
        beforeEnter: authGuard
    },
    { 
        name: 'single',
        path: "/:categorySlug/:productSlug", 
        component: SingleProduct,
        beforeEnter: authGuard
    },
    { 
        name: 'search',
        path: "/search", 
        component: SearchPage,
        beforeEnter: authGuard
    },
    { 
        name: 'checkout',
        path: "/checkout", 
        component: CheckoutPage,
        beforeEnter: authGuard
    },
    { 
        name: 'favourites',
        path: "/favourites", 
        component: FavouritesPage,
        beforeEnter: authGuard
    },
    { 
        name: 'account',
        path: "/account", 
        component: AccountPage,
        beforeEnter: authGuard
    },

]