<template>
<section id="category-page" class="page-container">
    <section id="category-page-header" v-if="isDataFetched">
        <BreadcrumbsNav v-if="isDataFetched" :data="getPathToBreadcrumbs"></BreadcrumbsNav>

        <div class="wrapper">
            <div class="header">
             <h1 class="categoryName">{{ category.name }}</h1>
            </div>
            <SubCategoriesNav v-if="getParentCategories[0]" :data="getParentCategories"></SubCategoriesNav>
        </div>
    </section>
    <section id="products-list" v-if="isDataFetched">
        <div class="wrapper">
            <div class="top-label">
                <p>{{ pagination.total }} {{ dli(pagination.total, "produkt", "produkty", "produktów") }}</p>
            </div>
            <div class="items">
                <ProductTile v-for="(item, key) in products" :key="key" :product="item"></ProductTile>
            </div>
            <div class="pagination">
                <ul>
                    <li v-for="index in Number(pagination.totalPages)" :key="index">
                        <router-link 
                        :to="'/'+category.slug+'?page='+index"
                        :class="{active: $route.query.page == index || (!$route.query.page && index == 1)}"
                        >{{ index }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</section>
</template>
<script>
import _ from 'lodash';
import axios from 'axios';

import BreadcrumbsNav from './parts/BreadcrumbsNav';
import SubCategoriesNav from './parts/SubCategoriesNav';
import ProductTile from './parts/ProductTile';


export default{
name: 'ProductsCategory',
data(){
    return {
        category: null,
        products: null,
        pagination: {
            total: null,
            totalPages: null
        },
        isDataFetched: false
    }
},
components: {
    BreadcrumbsNav,
    SubCategoriesNav,
    ProductTile
},
computed: {
    getCategorySlug(){
        return this.$route.params.categorySlug;
    },
    getParentCategories(){
        return _.filter(this.$store.getters.getCategories, { 'parent': this.category.id });
    },
    getPathToBreadcrumbs(){
        let path = [];
        if(this.category.parent){
            let parent = _.filter(this.$store.getters.getCategories, { 'id': this.category.parent });
            path.push({
                slug: parent[0].slug,
                name: parent[0].name
            });
        }
        path.push({
            slug: this.category.slug,
            name: this.category.name
        })
        return path;
    }
},
methods: {
    async getProducts(){
        let categorySlug = this.$route.params.categorySlug;
        const apiCategoryURL = "wp/v2/products-categories?slug="+categorySlug;
        try{
            let response = await axios.get(apiCategoryURL);
            this.category = response.data[0];
           
            let apiProductsURL = "wp/v2/products?products-categories="+this.category.id; 

            if(this.$route.query.page){
                apiProductsURL = "wp/v2/products?products-categories="+this.category.id+"&page="+this.$route.query.page;
            }
            
             let response2 = await axios.get(apiProductsURL);
             
             this.pagination.total = response2.headers['x-wp-total'];
             this.pagination.totalPages = response2.headers['x-wp-totalpages'];
             this.products = response2.data;

            this.isDataFetched = true;

        } catch(err){
          console.log('Err', err)
        }
      },
      dli( x, a, b, c ) {
		if ( x == 1 ) {
			return a;
		}
		if ( x % 10 > 1 && x % 10 < 5 && ! ( x % 100 >= 10 && x % 100 <= 21 ) ) {
			return b;
		}
		return c;
	}
},
watch:{
    $route (to, from){
        this.getProducts();
    }
},
mounted(){
    this.getProducts();
}
}
</script>
<style scoped>
</style>