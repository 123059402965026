<template>
<section id="user-login-page">
    <div class="oneoftwo">

        <div class="inner-wrapper">
            <div class="form-container">
                <div class="logo">
                    <img src="@/assets/images/logo.svg" alt="Fluidra Polska" />
                </div>

                <!-- login form -->
                <template v-if="!view.pwaInfo">
                    <div class="header">
                        <h1>Zaloguj się</h1>
                    </div>
                    <form action="#">
                        <label for="login">NIP</label>
                        <input :class="{'invalid': status.incorrect}" type="text" name="login" placeholder="Wprowadź NIP" v-model="form.login">

                        <label for="password">Hasło</label>
                        <input :class="{'invalid': status.incorrect}" type="password" name="password" placeholder="Hasło" v-model="form.pass">
                        <p><a href="#">Nie pamiętasz hasła?</a></p>
                        <button type="submit" @click.prevent="submitLoginForm()" :disabled="!submitActive">Zaloguj się</button>
                        <p v-if="status.incorrect" class="incorrectStatus">Nieprawidłowy login lub hasło.</p>
                        <span class="or">lub</span>
                        <a href="#"><button class="join">Dołącz do programu Fluidra Polska</button></a>
                    </form>
                    <div class="pwa-info">
                        <div class="inner">
                            <img src="@/assets/images/app-icon.svg" alt="">
                            <p><b>Dodaj aplikację do pulpitu urządzenia</b> i miej ją zawsze pod ręką!</p>
                            <p><a href="#"  @click.prevent="view.pwaInfo = !view.pwaInfo">Dowiedz się więcej</a></p>
                        </div>
                    </div>
                    </template>
                <!-- END login form -->
              <template v-else>
                <div class="header">
                    <h1>Dodaj aplikację do pulpitu</h1>
                 
                </div>
                <div class="pwa-steps">
                        <div class="item">
                            <p>1.Naciśnij przycisk udostępniania.</p>
                            <img src="@/assets/images/pwa-step1.svg" alt="">
                        </div>
                        <div class="item">
                            <p>2. Naciśnij “Dodaj stronę do”</p>
                            <img src="@/assets/images/pwa-step2.svg" alt="">
                        </div>
                        <div class="item">
                            <p>3. Naciśnij “Ekran startowy”</p>
                            <img src="@/assets/images/pwa-step3.svg" alt="">
                        </div>
                    </div>
                    <div class="btn"><button class="back" @click.prevent="view.pwaInfo = !view.pwaInfo"><span>Powrót</span></button></div>
            </template>
            </div>
        </div>

    </div>
    <div class="oneoftwo">
        <img src="@/assets/images/login-decor.jpg" class="login-decor" loading="lazy" />
    </div>
</section>

</template>
<script>
import axios from 'axios';

export default{
name: 'Login',
data(){
    return{
        view: {
            pwaInfo: false
        },
        status: {
            incorrect: false
        },
        form: {
            login: '',
            pass: ''
        }
    }
},
methods: {

    submitLoginForm(){
        axios.post("jwt-auth/v1/token", {
            username: this.form.login,
            password: this.form.pass
        }).then(({data}) => { 
            if(data.token){
                this.getUserData(data.token);
            } 
        })
        .catch(err => {
            if(err.status === 403){
                this.status.incorrect = true;
            } else {
                console.log('Err', err);
            }
        });
    },
    getUserData(token){
        axios.get("wp/v2/users/me", {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(({data}) => { 
            let userObj = {
                token: token,
                id: data.id,
                name: data.name,
                acf: data.acf
            }
            localStorage.setItem("user", JSON.stringify(userObj));
            this.$store.commit("setUserData", userObj);        
            this.$router.push('/home');
        })
        .catch(err => console.log('Err', err));
    }
},
computed: {
    submitActive(){
        if(this.form.login.length >= 3 && this.form.pass.length >= 1){
            return true;
        } else {
            return false;
        }
    }
}
}
</script>
<style lang="less" scoped>

</style>